<template>
  <!-- Error page-->
  <div>
     <nav-bar />
    <div class="misc-inner p-2 p-sm-3">
     
      <div class="misc-wrapper container">
        <div class="row text-justify">
          <h2 class="main-title w-100 text-center">Terms of use</h2>
          <p>
            The use of any product, service or feature (the “Materials”)
            available through the internet web sites accessible at
            <a href="https://www.dckap.com/">www.dckap.com</a> by any user of
            the Web Site (“you” or “your”) shall be governed by the following
          </p>
          <p><strong>TERMS OF USE</strong></p>
          <p>
            This Web site is provided by DCKAP (“DCKAP”), and may be used for
            informational purposes only. By using the site or downloading the
            Materials from the site, you agree to abide by the terms and
            conditions set forth in this notice. If you do not agree to abide by
            these terms and conditions please do not use the Web Site or
            download materials from the DCKAP Web Site.
          </p>
          <p>
            This Site, including all Materials present (excluding any applicable
            third party materials) , is the property of DCKAP and is copyrighted
            and protected by worldwide copyright laws and treaty provisions. You
            agree to comply with all copyright laws worldwide in your use of
            this Web Site and to prevent any unauthorized copying of the
            Materials.
          </p>
          <p>
            DCKAP does not grant any express or implied rights under any
            patents, trademarks, copyrights or trade secret information.
          </p>
          <p>
            DCKAP has business relationships with thousands of customers,
            suppliers, governments, and others. For convenience and simplicity,
            words like joint venture, partnership, and partner are used to
            indicate business relationships involving common activities and
            interests, and those words may not indicate precise legal
            relationships.
          </p>
          <p><strong>LIMITED LICENSE</strong></p>
          <p>
            Subject to the terms and conditions set forth in these Terms of Use,
            DCKAP grants you a non-exclusive, non-transferable, limited right to
            access, use and display this site and the materials thereon. You
            agree not to interrupt or attempt to interrupt the operation of the
            site in any way.
          </p>
          <p>
            <strong>THIRD PARTY CONTENT</strong>
          </p>
          <p>
            The Web Site makes information of third parties available, including
            articles, analyst reports, news reports, tools to facilitate
            calculation, company information and data about financial markets,
            including the NASDAQ and other financial markets and other data (the
            “Third Party Content”). You acknowledge and agree that the Third
            Party Content is not created or endorsed by DCKAP nor are any
            products or services offered through it. The provision of Third
            Party Content is for general informational purposes only and does
            not constitute a recommendation or solicitation to purchase or sell
            any securities or shares or to make any other type of investment or
            investment decision. In addition, the Third Party Content is not
            intended to provide tax, legal or investment advice. You acknowledge
            that the Third Party Content provided to you is obtained from
            sources believed to be reliable, but that no guarantees are made by
            the Web Site or the providers of the Third Party Content as to its
            accuracy, completeness, timeliness. You agree not to hold the Web
            Site, any business offering products or services through the Web
            Site or any provider of Third Party Content liable for any
            investment decision or other transaction you may make based on your
            reliance on or use of such data, or any liability that may arise due
            to delays or interruptions in the delivery of the Third Party
            Content for any reason.
          </p>
          <p>
            By using any Third Party Content, you may leave this Web Site and be
            directed to an external website, or to a website maintained by an
            entity other than DCKAP. If you decide to visit any such site, you
            do so at your own risk and it is your responsibility to take all
            protective measures to guard against viruses or other destructive
            elements. DCKAP makes no warranty or representation regarding, and
            does not endorse, any linked Web sites or the information appearing
            thereon or any of the products or services described thereon. Links
            do not imply that DCKAP or this Site sponsors, endorses, is
            affiliated or associated with, or is legally authorized to use any
            trademark, trade name, logo or copyright symbol displayed in or
            accessible through the links, or that any linked site is authorized
            to use any trademark, trade name, logo or copyright symbol of DCKAP
            or any of its affiliates or subsidiaries.
          </p>
          <p><strong>NO WARRANTIES</strong></p>
          <p>
            THIS SITE, THE INFORMATION AND MATERIALS ON THE SITE, AND ANY
            SOFTWARE MADE AVAILABLE ON THE SITE, ARE PROVIDED “AS IS” WITHOUT
            ANY REPRESENTATION OR WARRANTY, EXPRESS OR IMPLIED, OF ANY KIND,
            INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, NON
            INFRINGEMENT, OR FITNESS FOR ANY PARTICULAR PURPOSE. THERE IS NO
            WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, REGARDING THIRD PARTY
            CONTENT. THERE IS ALSO NO WARRANTY THAT THIS SITE WILL BE FREE OF
            ANY COMPUTER VIRUSES. SOME JURISDICTIONS DO NOT ALLOW FOR THE
            EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT
            APPLY TO YOU.
          </p>
          <p>
            <strong>LIMITATION OF DAMAGES</strong>
          </p>
          <p>
            IN NO EVENT SHALL DCKAP OR ANY OF ITS SUBSIDIARIES OR AFFILIATES BE
            LIABLE TO ANY ENTITY FOR ANY DIRECT, INDIRECT, SPECIAL,
            CONSEQUENTIAL OR OTHER DAMAGES (INCLUDING, WITHOUT LIMITATION, ANY
            LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF INFORMATION OR PROGRAMS
            OR OTHER DATA ON YOUR INFORMATION HANDLING SYSTEM) THAT ARE RELATED
            TO THE USE OF, OR THE INABILITY TO USE, THE CONTENT, MATERIALS, AND
            FUNCTIONS OF THE WEB SITE OR ANY LINKED WEB SITE, EVEN IF DCKAP IS
            EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <p><strong>DISCLAIMER</strong></p>
          <p>
            THE WEB SITE MAY CONTAIN INACCURACIES AND TYPOGRAPHICAL AND CLERICAL
            ERRORS. DCKAP EXPRESSLY DISCLAIMS ANY OBLIGATION TO UPDATE THIS SITE
            OR ANY OF THE MATERIALS ON THIS SITE. DCKAP DOES NOT WARRANT THE
            ACCURACY OR COMPLETENESS OF THE MATERIALS OR THE RELIABILITY OF ANY
            ADVICE, OPINION, STATEMENT OR OTHER INFORMATION DISPLAYED OR
            DISTRIBUTED THROUGH THE SITE. YOU ACKNOWLEDGE THAT ANY RELIANCE ON
            ANY SUCH OPINION, ADVICE, STATEMENT, MEMORANDUM, OR INFORMATION
            SHALL BE AT YOUR SOLE RISK. DCKAP RESERVES THE RIGHT, IN ITS SOLE
            DISCRETION, TO CORRECT ANY ERRORS OR OMISSIONS IN ANY PORTION OF THE
            SITE. DCKAP MAY MAKE ANY OTHER CHANGES TO THE SITE, THE MATERIALS
            AND THE PRODUCTS, PROGRAMS, SERVICES OR PRICES (IF ANY) DESCRIBED IN
            THE SITE AT ANY TIME WITHOUT NOTICE.
          </p>
          <p><strong>INDEMNITY</strong></p>
          <p>
            You agree to indemnify and hold harmless DCKAP, its subsidiaries and
            affiliates from any claim, cost, expense, judgment or other loss
            relating to your use of this Web Site, including without limitation
            of the foregoing, any action you take which is in violation of the
            terms and conditions of these Terms of Use.
          </p>
          <p><strong>CHANGES: INDEMNITY</strong></p>
          <p>
            DCKAP reserves the right, at its sole discretion, to change, modify,
            add or remove any portion of these Terms of Use in whole or in part,
            at any time. Changes in these Terms of Use will be effective when
            notice of such change is posted. Your continued use of the Web Site
            after any changes to these Terms of Use are posted will be
            considered acceptance of those changes. DCKAP may terminate, change,
            suspend or discontinue any aspect of the DCKAP Web Site, including
            the availability of any features of the Site, at any time. DCKAP may
            also impose limits on certain features and services or restrict your
            access to parts or all of the Web Site without notice or liability.
            DCKAP may terminate the authorization, rights, and license given
            above and, upon such termination, you shall immediately destroy all
            Materials.
          </p>
          <p>
            <strong>INTERNATIONAL USERS AND CHOICE OF LAW</strong>
          </p>
          <p>
            This Site is controlled, operated and administered by DCKAP from its
            offices within United States and India. DCKAP makes no
            representation that materials at this site are appropriate or
            available for use at other locations outside of United States and
            India and access to them from territories where their contents are
            illegal is prohibited. You may not use the Web Site or export the
            Materials in violation of any applicable export laws and
            regulations. If you access this Site from a location outside of
            India, you are responsible for compliance with all local laws. These
            Terms of Use shall be governed by the laws of India, without giving
            effect to its conflict of laws provisions. You agree that the
            appropriate court in Bangalore, India, will have the exclusive
            jurisdiction to resolve all disputes arising under these Terms of
            Use, and you consent to personal jurisdiction in such forum.
          </p>
          <p>
            These Terms of Use constitutes the entire agreement between DCKAP
            and you with respect to your use of the Web Site. Any cause of
            action you may have with respect to your use of the Web Site must be
            commenced within one (1) year after the claim or cause of action
            arises. If for any reason a court of competent jurisdiction finds
            any provision of these Terms of Use or portion thereof, to be
            unenforceable, that provision shall be enforced to the maximum
            extent permissible so as to effect the intent of these Terms of Use,
            and the remainder of these Terms of Use shall continue in full force
            and effect.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import store from "@/store/index";
import { $themeConfig } from "@themeConfig";
const NavBar = () => import("@/components/NavBar.vue");

export default {
  components: {
    BLink,
    BButton,
    BImg,
    NavBar
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/error.svg"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/error-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  setup() {
    // App Name
    const { appName, appDarkLogo } = $themeConfig.app;
    return {
      appName,
      appDarkLogo,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
.brand-text {
  line-height: 42px;
  margin-bottom: 0;
}
</style>
